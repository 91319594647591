<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>管理员角色</el-breadcrumb-item>
            <el-breadcrumb-item>编辑/添加</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="序号">
          <el-input v-model.number="form.sorder" type="number"></el-input>
        </el-form-item>
        <el-form-item label="名称" :error="$v.form.name.$error ? '请输入名称' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="createAd">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { editRequest, getdatilRequest } from '@/api/sys_role'
import ImageUpload from '@/components/imageUpload'
import { required } from 'vuelidate/lib/validators'
import utils from '@/utils/util'
export default {
  typeName: 'Articletypecreate',
  data() {
    return {
      classify: [],
      form: {
        id: 0,
        name: '',

        addtime: '',
      },
    }
  },
  components: {
    'image-upload': ImageUpload,
  },
  created() {
    this.form.id = this.$route.query['id']

    this.getdatil()
  },
  methods: {
    back() {
      this.$router.back()
    },
    getdatil() {
      getdatilRequest({
        Id: this.form.id,
      }).then((res) => {
        this.form = res.data
      })
    },

    createAd() {
      this.$v.form.$touch()
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form))

        editRequest(params).then((res) => {
          if (res.code == 1) {
            this.$router.go(-1)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
